/* eslint-disable import/namespace */
import { queryClient } from "config"
import { find, get, isArray } from "lodash"
import { Suspense } from "react"
import notification from "services/notification"
import constants from "store/constants"
import yupLocale from "store/yupLocale"
import * as yup from "yup"

yup.setLocale(yupLocale)

function checkStatus(status) {
  let statusType = "ok"
  if (status < 200 && status > 299) statusType = "error"
  return statusType
}

const validateForm = ({ fields }) => {
  const schema = {}

  if (isArray(fields))
    fields.forEach((field) => {
      const { name = "" } = field

      switch (get(field, "type")) {
        case "number":
          schema[name] = yup.number().typeError("Raqam kiritilishi kerak")
          break
        case "object": {
          if (get(field, "nullable")) {
            schema[name] = yup.object().nullable()
          } else {
            schema[name] = yup.object().typeError("Malumot tanlanishi kerak")
          }
          break
        }
        case "boolean":
          schema[name] = yup.boolean()
          break
        case "array":
          schema[name] = yup.array()
          break
        case "email":
          schema[name] = yup
            .string()
            .email("link https://example.uz/any ko'rinishida bo'lishi kerak")
          break
        case "url":
          schema[name] = yup.string().url()
          break
        case "json":
          schema[name] = yup.string().test("valid", "Json formatida kiriting", (value) => {
            try {
              JSON.stringify(JSON.parse(value))
              return true
            } catch (e) {
              return false
            }
          })
          break
        default: {
          schema[name] = yup.string()
        }
      }
      if (get(field, "required")) {
        schema[name] = schema[name].required()
      }
      if (get(field, "type") === "boolean" && get(field, "required")) {
        schema[name] = schema[name].test(
          "is boolean",
          "Maydon belgilanishi kerak",
          (value) => value === true,
        )
      }
      if (get(field, "min")) schema[name] = schema[name].min(get(field, "min"))
      if (get(field, "max")) schema[name] = schema[name].max(get(field, "max"))
      if (get(field, "nullable")) schema[name] = schema[name].nullable()
    })
  return yup.object(schema)
}

const sortByCreator = (setSort) => (col, sort) => {
  if (sort === `-${col}`) {
    setSort(`+${col}`)
  } else {
    setSort(`-${col}`)
  }
}

function filterRoutes(routes) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  if (Array.isArray(permissions)) permissions.push("profile")

  // routes.forEach(({ children }, i) => {
  //   newRoutes[i].children = Array.isArray(permissions)
  //     ? children.filter(
  //         (route) => Array.isArray(permissions) && permissions.includes(route.permission),
  //       )
  //     : []
  // })
  // if (isSuperuser) return routes

  // return newRoutes
  return routes
}

function filterNav(navs) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  const isSuperuser = get(queryClient.getQueryData(["globalState"]), "user.is_superuser")

  if (isSuperuser) return navs
  return navs.filter(
    ({ permission }) => Array.isArray(permissions) && permissions.includes(permission),
  )
}

function checkPermissions(list = []) {
  const permissions = get(queryClient.getQueryData(["globalState"]), "user.permissions")
  const isSuperuser = get(queryClient.getQueryData(["globalState"]), "user.is_superuser")

  const avaliablePermissions = list.filter(
    (item) => Array.isArray(permissions) && permissions.includes(item),
  )

  if (isSuperuser) return true

  return avaliablePermissions.length > 0
}

function toReadeable(number) {
  return new Intl.NumberFormat().format(number || 0)
}

function copy(text) {
  try {
    const input = document.querySelector("#copy")
    input.value = text
    input.select()
    document.execCommand("copy")
    window.navigator.clipboard.writeText(text)
    notification.success("Nusxa olindi!")
  } catch (err) {
    notification.success("Nusxalashda xatolik")
  }
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

function getFileTypeFromUrl(url) {
  const ext = url.split(".").pop()

  if (constants.imageTypes.includes(ext)) return "image"
  if (constants.videoTypes.includes(ext)) return "video"
  if (constants.audioTypes.includes(ext)) return "audio"
  if (constants.wordTypes.includes(ext)) return "doc"
  if (constants.excelTypes.includes(ext)) return "excel"
  if (constants.archiveTypes.includes(ext)) return "archive"

  return null
}

function formatPhoneNumber(phoneNumber = "") {
  const areaCode = phoneNumber.slice(4, 6)
  const prefix = phoneNumber.slice(6, 9)
  const lineNumber1 = phoneNumber.slice(9, 11)
  const lineNumber2 = phoneNumber.slice(11, 13)

  return `+998 ${areaCode} ${prefix}-${lineNumber1}-${lineNumber2}`
}

function regionReducer(addresses) {
  return Array.isArray(addresses)
    ? addresses.reduce((acc, curr) => {
        const items = get(curr, "districts") || []
        const newItems = items.map((item) => ({
          ...item,
          value: get(item, "id"),
          label: `${get(curr, "name")},${get(item, "name")}`,
        }))
        return [...acc, ...newItems]
      }, [])
    : []
}

const withSuspense = (Component) => {
  const wrappedComponent = (props) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  )
  return wrappedComponent
}

function getAccountFromAccounts({ attribute, accounts: _accounts }) {
  const accounts =
    _accounts || get(queryClient.getQueryData(["globalState"]), "user.accounts") || []

  const account = find(accounts, {
    type: "default",
  })

  if (attribute === "balance") return toReadeable(get(account, attribute, 0))

  if (!attribute) return account

  return get(account, attribute, 0)
}

export default {
  getAccountFromAccounts,
  formatPhoneNumber,
  checkStatus,
  validateForm,
  sortByCreator,
  filterRoutes,
  filterNav,
  checkPermissions,
  toReadeable,
  copy,
  formatBytes,
  getFileTypeFromUrl,
  regionReducer,
  withSuspense,
}
